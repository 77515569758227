import { Col, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/logo/new.svg";
import { BiMenu as MenuIcon } from "react-icons/bi";
import { Button } from "react-bootstrap";
import "./styles/MobileNavigation.scss";
import { BlueContainer } from "components/container";
import routes from "pages/routes";
import { Link } from "react-router-dom";
import MobileNavigation from "./MobileNavigation";
import { useState } from "react";

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onMenuIsOpen = (status) => {
    setIsOpen(status);
  };

  return (
    <>
      <MobileNavigation isOpen={isOpen} onMenuIsOpen={onMenuIsOpen} />
      <BlueContainer>
        <Row className="py-3">
          <Col>
            <Link to={routes.home}>
              <Logo />
            </Link>
          </Col>
          <Col>
            <Button
              className="bg-white float-right p-1 border-0"
              onClick={() => onMenuIsOpen(true)}
            >
              <MenuIcon className="tc-blue-4" style={{ fontSize: "50px" }} />
            </Button>
          </Col>
        </Row>
      </BlueContainer>
    </>
  );
};

export default MobileHeader;
