import { WhiteContainer } from "components/container";
import PageTitle from "components/page_title/PageTitle";
// import SmallPersonCard from "components/person-cards/SmallPersonCard";
import T from "locales";
import { Col, Row } from "react-bootstrap";
// import { CONTACT_PERSONS } from "./texts";
import config from "config";
import ContactForm from "./components/contact-form/ContactForm";
import GoogleMaps from "./components/google-maps/GoogleMaps";
import LazyLoadImg from "components/lazy-load-img/LazyLoadImg";
import blueLogoImage from "assets/logo/blue.png";

const ContactPage = () => {
  return (
    <>
      <PageTitle title={<T k="contact_title" />} />
      <WhiteContainer>
        <Row className="my-5">
          <Col
            xs={{ span: 12, order: "last" }}
            md={{ span: 6, order: "first" }}
            lg="4"
            className="mb-3"
          >
            <h2>
              <T k="contact_write_message" />
            </h2>
            <ContactForm />
          </Col>
          <Col xs="12" md="6" lg={{ span: 7, offset: 1 }}>
            <Row className="mb-5">
              <Col lg="6" className="mb-4 d-none d-lg-block">
                <LazyLoadImg src={blueLogoImage} />
              </Col>
              <Col xs="12" lg="6" className="tc-blue-4">
                <Row className="tfs-small">
                  <Col>
                    <strong>
                      <T k="phone_short" />{" "}
                    </strong>
                    <a href={"tel: " + config.CONTACT.phone}>
                      {config.CONTACT.phone}
                    </a>
                  </Col>
                </Row>
                <Row className="tfs-small pt-2">
                  <Col>
                    <strong>
                      <T k="email" />{" "}
                    </strong>
                    <a href={"mailto: " + config.CONTACT.email}>
                      {config.CONTACT.email}
                    </a>
                  </Col>
                </Row>
                <Row className="tfs-small pt-2">
                  <Col>
                    <strong>
                      <T k="address" />{" "}
                    </strong>
                    {config.CONTACT.address}
                  </Col>
                </Row>
                <Row>
                  <Col className="tfs-very-small pt-2">
                    <T k="contact_building_location" />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <GoogleMaps />
              </Col>
            </Row>
            {/* <Row className="mb-3">
              {CONTACT_PERSONS.map((person, i) => (
                <Col key={i} xs="12" lg="6" className="mb-4">
                  <SmallPersonCard {...person} />
                </Col>
              ))}
            </Row> */}
          </Col>
        </Row>
      </WhiteContainer>
    </>
  );
};

export default ContactPage;
