import MaijaKuleImg from "assets/persons/maija_kule.jpg";
import MaijaKuleLargeImg from "assets/persons/maija_kule_large.jpg";

import MarisKulisImg from "assets/persons/maris_kulis.jpg";
import MarisKulisLargeImg from "assets/persons/maris_kulis_large.jpg";

import ElviraSimfaImg from "assets/persons/elvira_simfa.jpg";
import ElviraSimfaLargeImg from "assets/persons/elvira_simfa_large.jpg";

// import VelgaVevereImg from "assets/persons/velga_vevere.jpg";
// import VelgaVevereLargeImg from "assets/persons/velga_vevere_large.jpg";

import MaraKiopeImg from "assets/persons/mara_kiope.jpg";
import MaraKiopeLargeImg from "assets/persons/mara_kiope_large.jpg";

import EllaBucenieceImg from "assets/persons/ella_buceniece.jpg";
import EllaBucenieceLargeImg from "assets/persons/ella_buceniece_large.jpg";

import SolveigaKruminaKonkovaImg from "assets/persons/solveiga_krumina_konkova.jpg";
import SolveigaKruminaKonkovaLargeImg from "assets/persons/solveiga_krumina_konkova_large.jpg";

import EdmundsSupulisImg from "assets/persons/edmunds_supulis.jpg";
import EdmundsSupulisLargeImg from "assets/persons/edmunds_supulis_large.jpg";

import GintaElksneImg from "assets/persons/ginta_elksne.jpg";
import GintaElksneLargeImg from "assets/persons/ginta_elksne_large.jpg";

import IevaGardaRozenbergaImg from "assets/persons/ieva_garda_rozenberga.jpg";
import IevaGardaRozenbergaLargeImg from "assets/persons/ieva_garda_rozenberga_large.jpg";

import KasparsZellisImg from "assets/persons/kaspars_zellis.jpg";
import KasparsZellisLargeImg from "assets/persons/kaspars_zellis_large.jpg";

import MaijaKruminaImg from "assets/persons/maija_krumina.jpg";
import MaijaKruminaLargeImg from "assets/persons/maija_krumina_large.jpg";

import IgorsGubenkoImg from "assets/persons/igors_gubenko.jpg";
import IgorsGubenkoLargeImg from "assets/persons/igors_gubenko_large.jpg";

import IlzeKorolevaImg from "assets/persons/ilze_koroleva.jpg";
import IlzeKorolevaLargeImg from "assets/persons/ilze_koroleva_large.jpg";

import IntaMierinaImg from "assets/persons/inta_mierina.jpg";
import IntaMierinaLargeImg from "assets/persons/inta_mierina_large.jpg";

import DianaPopovaImg from "assets/persons/diana_popova.jpg";
import DianaPopovaLargeImg from "assets/persons/diana_popova_large.jpg";

const ADMINISTRATION = {
  main: [
    {
      name: "Solveiga Krūmiņa-Koņkova",
      title: "Institūta direktore",
      description: "",
      image: SolveigaKruminaKonkovaImg,
      largeImage: SolveigaKruminaKonkovaLargeImg,
    },
    // {
    //   name: "Ilze Koroļeva",
    //   title: "Institūta direktora vietnieks",
    //   description: "",
    //   image: IlzeKorolevaImg,
    //   largeImage: IlzeKorolevaLargeImg,
    // },
  ],
  secondary: [
    {
      name: "Arta Jāne",
      title: "Redaktore",
      image: null,
    },
    {
      name: "Maija Krūmiņa",
      title: "Zinātniskā sekretāre",
      image: MaijaKruminaImg,
      largeImage: MaijaKruminaLargeImg,
    },
    ],
};

const COUNCIL = {
  secondary: [
    {
      name: "Maija Kūle",
      title: "Dr.phil.",
      image: MaijaKuleImg,
      largeImage: MaijaKuleLargeImg,
    },
    {
      name: "Ilze Koroļeva",
      title: "Dr.sc.soc.",
      image: IlzeKorolevaImg,
      largeImage: IlzeKorolevaLargeImg,
    },
    {
      name: "Inta Mieriņa",
      title: "Dr.sc.soc. ",
      image: IntaMierinaImg,
      largeImage: IntaMierinaLargeImg,
    },
    {
      name: "Kaspars Zellis",
      title: "Dr.hist.",
      image: KasparsZellisImg,
      largeImage: KasparsZellisLargeImg,
    },
    {
      name: "Ella Buceniece",
      title: "Dr.phil.",
      image: EllaBucenieceImg,
      largeImage: EllaBucenieceLargeImg,
    },
    {
      name: "Elvīra Šimfa",
      title: "Dr.phil.",
      image: ElviraSimfaImg,
      largeImage: ElviraSimfaLargeImg,
    },
    {
      name: "Igors Gubenko",
      title: "Dr.phil.",
      image: IgorsGubenkoImg,
      largeImage: IgorsGubenkoLargeImg,
    },
    {
      name: "Māris Kūlis",
      title: "Dr.phil.",
      image: MarisKulisImg,
      largeImage: MarisKulisLargeImg,
    },
    {
      name: "Māra Kiope",
      title: "Dr.phil",
      image: MaraKiopeImg,
      largeImage: MaraKiopeLargeImg,
    },
    {
      name: "Maija Krūmiņa",
      title: "PhD",
      image: MaijaKruminaImg,
      largeImage: MaijaKruminaLargeImg,
    },
    {
      name: "Inese Šūpule",
      title: "Dr.sc.soc",
      image: null,
    },
  ],
};

const OTHER = [
  {
    name: "Aleksandrs Aleksandrovs",
    title: "pētnieks",
    image: null,
  },
  {
    name: "Andrejs Balodis",
    title: "pētnieks (Dr.)",
    image: null,
  },
  {
    name: "Dagmāra Beitnere-Le Galla",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Raivis Bičevskis",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Leo Dribins",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Ginta Elksne",
    title: "pētniece",
    image: GintaElksneImg,
    largeImage: GintaElksneLargeImg,
  },
  {
    name: "Māra Grīnfelde",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Daina Grosa",
    title: "pētniece",
    image: null,
  },
  {
    name: "Ģirts Jankovskis",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Mārtiņš Kaprāns",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Rita Kaša",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Svetlana Kovaļčuka",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Maija Krūmiņa",
    title: "vadošā pētniece",
    image: MaijaKruminaImg,
    largeImage: MaijaKruminaLargeImg,
  },
  {
    name: "Rihards Kūlis",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Maija Kūle",
    title: "vadošā pētniece",
    image: MaijaKuleImg,
    largeImage: MaijaKuleLargeImg,
  },
  {
    name: "Kitija Mirončuka",
    title: "zinātniskā asistente",
    image: null,
  },
  {
    name: "Nadežda Pazuhina",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Diāna Popova",
    title: "zinātniskais asistents",
    image: DianaPopovaImg,
    largeImage: DianaPopovaLargeImg,
  },
  {
    name: "Maruta Pranka",
    title: "pētniece",
    image: null,
  },
  {
    name: "Aīda Rancāne",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Ieva Garda-Rozenberga",
    title: "vadošais eksperts",
    image: IevaGardaRozenbergaImg,
    largeImage: IevaGardaRozenbergaLargeImg,
  },
  {
    name: "Māra Rubene",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Inese Runce",
    title: "vadošā pētniece, lektora p.i.",
    image: null,
  },
  {
    name: "Andris Saulītis",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Sigita Sniķere",
    title: "pētniece",
    image: null,
  },
  {
    name: "Vieda Šellija",
    title: "vadošā pētniece",
    image: null,
  },
  {
    name: "Elvīra Šimfa",
    title: "vadošā pētniece",
    image: ElviraSimfaImg,
    largeImage: ElviraSimfaLargeImg,
  },
  {
    name: "Inese Šūpule",
    title: "vadošā pētniece, pasniedzējs (Dr.)",
    image: null,
  },
  {
    name: "Edmunds Šūpulis",
    title: "pētnieks",
    image: EdmundsSupulisImg,
    largeImage: EdmundsSupulisLargeImg,
  },
  {
    name: "Ilze Trapenciere",
    title: "pētniece",
    image: null,
  },
  {
    name: "Vladislavs Volkovs",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Uldis Vēgners",
    title: "vadošais pētnieks",
    image: null,
  },
  {
    name: "Oksana Žabko",
    title: "pētnieks (Dr.)",
    image: null,
  },
];

export { ADMINISTRATION, COUNCIL, OTHER };