const locales = {
  loading: {
    lv: "Ielādē",
    en: "Loading...",
  },
  phone_short: {
    lv: "Tālr.:",
    en: "Phone:",
  },
  email: {
    lv: "E-pasts:",
    en: "E-mail:",
  },
  address: {
    lv: "Adrese:",
    en: "Address:",
  },
  archive: {
    lv: "Arhīvs",
    en: "Archive",
  },
  news: {
    lv: "Jaunumi",
    en: "News",
  },
  event: {
    lv: "Notikumi",
    en: "Events",
  },
  publications: {
    lv: "Publikācijas",
    en: "Publications",
  },
  publication_newses: {
    lv: "Jaunākie izdevumi",
    en: "Newest publications",
  },
  publication_all: {
    lv: "Visas publikācijas",
    en: "All publications",
  },
  latest_publications: {
    lv: "Jaunākās publikācijas",
    en: "Latest publications",
  },
  publication_all_magazines: {
    lv: "Visi žurnāli",
    en: "All Journals",
  },
  publication_page_desc: {
    lv: "Institūta zinātnieki sagatavo un publicē monogrāfijas, rakstu krājumus, tulkojumus, socioloģisko pētījumu atskaites un pārskatus. Daudzas grāmatas ir piedzīvojušas vairākkārtīgus izdevumus, lasītāju mīlestību un pateicību. Grāmatu uzdevums ir veidot filozofiskās un socioloģiskās domas vidi Latvijā un gaidīt savu lasītāju.",
    en: "Scientists of the Institute prepare and publish monographs, collections of scientific papers, translations,  reports and reviews of sociological research. Many books have been published in multiple editions and have received an appraisal and gratitude from the readership. The task of the books is to shape the environment of philosophical and sociological thought in Latvia and to wait for their readers.",
  },
  publication_view: {
    lv: "Skatīt publikācijas",
    en: "View publications",
  },
  other: {
    lv: "Zinātniskais personāls",
    en: "Scientific staff",
  },
  about_us_title: {
    lv: "Par mums",
    en: "About us",
  },
  about_us_desc: {
    lv: "Latvijas Universitātes (LU) Filozofijas un socioloģijas institūts (FSI) ir zinātniska institūcija, kurā tiek pētītas filozofijas aktualitātes un vēsture, kultūras un sabiedrības norises. Institūta darbība ietver zinātnisku grāmatu un rakstu izstrādi un publicēšanu, zinātnisku žurnālu sagatavošanu, līdzdalību zinātniskās konferencēs un semināros, populārzinātniskos pasākumos un diskusijās, socioloģisku pētījumu veikšanu, zinātnisku projektu izstrādi un realizāciju.",
    en: "The Institute of Philosophy and Sociology of the University of Latvia (UL) is a scientific institution in which the realities and history of philosophy, cultural and societal developments are studied. The activities of the Institute include the development and publication of scientific books and articles, the preparation of scientific journals, participation in scientific conferences and seminars, popular science events and discussions, the conduct of sociological studies, the development and realisation of scientific projects.",
  },
  home_title: {
    lv: "Filozofijas un socioloģijas institūts",
    en: "The Institute of Philosophy and Sociology",
  },
  publication_title: {
    lv: "Publikācijas",
    en: "Publications",
  },
  contact_title: {
    lv: "Kontakti",
    en: "Contacts",
  },
  contact_desc: {
    lv: "Kontakti",
    en: "Contacts",
  },
  event_title: {
    lv: "Notikumi",
    en: "Events",
  },
  event_desc: {
    lv: "Notikumi",
    en: "Events",
  },
  event_this_year: {
    lv: "Notikumi šogad",
    en: "Thiss years events",
  },
  gallery_title: {
    lv: "Galerija",
    en: "Gallery",
  },
  gallery_desc: {
    lv: "Galerija",
    en: "Gallery",
  },
  news_title: {
    lv: "Jaunumi",
    en: "Latest news",
  },
  news_desc: {
    lv: "Jaunumi",
    en: "News",
  },
  news_this_year: {
    lv: "Jaunumi šogad",
    en: "Thiss years news",
  },
  personnel_title: {
    lv: "Personāls",
    en: "Personnel",
  },
  see_all_button_title: {
    lv: "skatīt visus",
    en: "see all",
  },
  see_all_button_title_2: {
    lv: "skatīt visas",
    en: "see all",
  },
  main_activities_title: {
    lv: "Galvenie darbības veidi",
    en: "Main fields of research",
  },
  main_activities_paragraph: {
    lv: "LU Filozofijas un socioloģijas institūts veic pētniecību humanitāro un sociālo zinātņu jomās. Zinātniskā darbība organizēta divos tematiskajos lokos – filozofijā un socioloģijā.",
    en: "LU Institute of Philosophy and Sociology conducts research in the fields of the humanities and social sciences. Research work is organized within two thematic spheres – philosophy and sociology.",
  },
  main_activities_ssr_title: {
    lv: "Sociālo zinātņu pētījumu virzieni",
    en: "Themes of Social Science Research",
  },
  main_activities_ssr_text_1: {
    lv: "Etnopolitika, etniskās attiecības un sabiedrības integrācija",
    en: "Ethnopolitics, ethnic relations, migration and social integration",
  },
  main_activities_ssr_text_2: {
    lv: "Starptautiski socioloģiski salīdzinoši pētījumi",
    en: "International comparative sociological research",
  },
  main_activities_ssr_text_3: {
    lv: "Migrācijas procesu izpēte",
    en: "Research of the migration processes",
  },
  main_activities_ssr_text_4: {
    lv: "Jaunatnes un izglītības pētniecība",
    en: "Youth and education research",
  },
  main_activities_ssr_text_5: {
    lv: "Sociālā atstumtība",
    en: "Social exclusion",
  },
  main_activities_ssr_text_6: {
    lv: "Sabiedrības veselības sociālo aspektu izpēte",
    en: "Research of the social aspects of public health",
  },
  main_activities_ssr_text_7: {
    lv: "Mutvārdu vēsture, tās resursu izmantošana mūsdienu kultūras sociālo un identitātes procesu analīzē",
    en: "Oral history, analysis of making use of its resources in social and identity processes in contemporary culture",
  },
  main_activities_fr_title: {
    lv: "Filozofisko pētījumu virzieni",
    en: "Philosophical research areas",
  },
  main_activities_fr_text_1: {
    lv: "Latvijas filozofiskās domas un ideju vēstures pētījumi",
    en: "Research into the history of Latvian philosophical thought and ideas",
  },
  main_activities_fr_text_2: {
    lv: "Rietumu mūsdienu filozofijas virzienu pētījumi, klasisko darbu tulkošana",
    en: "Research into the contemporary Western philosophical trends, translation of classic works",
  },
  main_activities_fr_text_3: {
    lv: "Reliģijas filozofija un akadēmiskā reliģijpētniecība",
    en: "Philosophy of religion and academic study of religions",
  },
  sign_up_title: {
    lv: "Pierakstīties jaunumiem",
    en: "Sign up for our newsletter",
  },
  sign_up_text: {
    lv: "Atstāj savu e-pasta adresi, lai saņemtu informāciju par jaunākajiem rakstiem mūsu mājaslapā, kā arī dažādiem pasākumiem un aktivitātēm.",
    en: "Subscribe to our newsletter to receive the freshest information about the latest articles on our website, as well as upcoming events and other activities.",
  },
  sign_up_button: {
    lv: "pierakstīties",
    en: "sign up",
  },
  email_address_text: {
    lv: "Tava e-pasta adrese:",
    en: "Your e-mail address:",
  },
  newsletter_submit_success: {
    lv: "Esat veiksmīgi pierakstījies jaunumiem!",
    en: "You've successfully subscribed to our newsletter.",
  },
  month_jan: {
    lav: "Janvāris",
    en: "January",
  },
  month_feb: {
    lv: "Februāris",
    en: "February",
  },
  month_march: {
    lv: "Marts",
    en: "March",
  },
  month_apr: {
    lv: "Aprīlis",
    en: "April",
  },
  month_may: {
    lv: "Maijs",
    en: "May",
  },
  month_jun: {
    lv: "Jūnijs",
    en: "June",
  },
  month_jul: {
    lv: "Jūlijs",
    en: "July",
  },
  month_aug: {
    lv: "Augusts",
    en: "August",
  },
  month_sept: {
    lv: "Septembris",
    en: "September",
  },
  month_oct: {
    lv: "Oktobris",
    en: "October",
  },
  month_nov: {
    lv: "Novembris",
    en: "November",
  },
  month_dec: {
    lv: "Decembris",
    en: "December",
  },
  go_back: {
    lv: "Atpakaļ",
    en: "Back",
  },
  gallery_is_empty: {
    lv: "Galerija ir tukša",
    en: "Gallery is empty",
  },
  about_us_img_title_1: {
    lv: "Zinātniskās padomes sēde 2021",
    en: "Session/Meeting of the Scientific Council 2021",
  },
  about_us_img_title_2: {
    lv: "Latvijas universitātes galvenā ēka",
    en: "The main building of the University of Latvia",
  },
  about_us_img_title_3: {
    lv: "Latvijas Zinātņu akadēmija",
    en: "The Latvian Academy of Sciences",
  },
  about_us_img_title_4: {
    lv: "Kerkoviusa nams, LU Humanitāro un sociālo zinātņu pētījumu centrs",
    en: "Kerkovius’s House, The Research Centre for Humanities and Social Sciences of the University of Latvia",
  },
  about_us_text_1: {
    lv: "LU Filozofijas un socioloģijas institūta pārvaldes institūcijas ir Zinātnieku pilnsapulce, Zinātniskā padome, direktors.",
    en: "The governing bodies of the Institute of Philosophy and Sociology of the University of Latvia are the Assembly of Scientists, the Scientific Council, and the Director.",
  },
  about_us_text_2: {
    lv: "Savā darbībā Institūts ievēro Zinātniskās darbības likumu, Publisko aģentūru likumu, Augstskolu likumu, LU Satversmi, LU Senāta lēmumus, Nolikumu un citus spēkā esošus normatīvos tiesību aktus.",
    en: "The Institute functions in compliance with the Law on Scientific Activities, the Law on Public Agencies, the Law on Higher Education Institutions, the Satversme of the University of Latvia, the decisions of the Senate of the University of Latvia, the Regulations and other valid normative legal acts.",
  },
  about_us_text_3: {
    lv: "Institūta augstākā lēmējinstitūcija ir Zinātnieku pilnsapulce, kas sastāv no Institūtā strādājošiem zinātņu doktoriem un habilitētajiem zinātņu doktoriem.",
    en: "The highest decision-making body of the Institute is the Assembly of Scientists that is composed of Doctors of Science and Habilitated Doctors of Science working at the Institute.",
  },
  about_us_text_4: {
    lv: "Zinātniskās padomes kompetencē ietilpst zinātniskās darbības galveno virzienu noteikšana, institūta direktora, asistentu, pētnieku un vadošu pētnieku ievēlēšana u.c. funkcijas. Zinātnisko padomi, kas sastāv no 11 locekļiem, ievēl institūta Zinātnieku pilnsapulce.",
    en: "The Scientific Council is responsible for defining the main directions of scientific activity, electing the Institute's Director, assistants, researchers and senior researchers, among other functions. The Scientific Council, consisting of 11 members, is elected by the Assembly of the Institute's Scientists.",
  },
  about_us_text_5: {
    lv: "LU Filozofijas un socioloģijas institūta aizsākumi meklējami jau gandrīz trīsdesmit gadus senā pagātnē – 1981. gadā, kad tas tika iekļauts Latvijas Zinātņu akadēmijā ar nosaukumu Filozofijas un tiesību institūts.",
    en: "The Institute was founded in 1981 as Latvia Academy of Sciences Philosophy and Law Institute, transformed into Latvia Academy of Sciences Philosophy and Sociology Institute, then into University of Latvia Philosophy and Sociology Institute.",
  },
  about_us_text_6: {
    lv: "1991. gadā institūts mainīja savas darbības profilu un tika pārdēvēts par Filozofijas un socioloģijas institūtu. 1998. gadā institūts tika integrēts Latvijas Universitātē kā patstāvīga juridiska persona, kuras zinātnisko darbību pārraudzīja Latvijas Universitāte, taču tas joprojām atradās LR Izglītības un zinātnes ministrijas pakļautībā.",
    en: "In 1991, the Institute changed its profile and got renamed - the Institute of Philosophy and Sociology. In 1998, the Institute got integrated into the University of Latvia as an independent legal entity, whose scientific activities were supervised by the University of Latvia; however, it still functioned under the authority of the Ministry of Education and Science of the Republic of Latvia.",
  },
  about_us_text_7: {
    lv: "Līdz ar 2006. gadu Filozofijas un socioloģijas institūts ar LU Senāta lēmumu tika pārveidots par LU aģentūru un līdz 31.12.2015 funkcionēja kā Latvijas Universitātes dibināta aģentūra 'LU Filozofijas un socioloģijas institūts'. Sākot ar 01.01.2016 LU FSI tiek konsolidēts un pārdēvēts par 'Latvijas Universitātes Filozofijas un socioloģijas institūtu'.",
    en: "In 2006, with the decision of the Senate of the University of Latvia, the Institute of Philosophy and Sociology got transformed into an agency of the University of Latvia. And it functioned as an agency of the University of Latvia 'Institute of Philosophy and Sociology of the University of Latvia' until 31.12.2015. As of 01.01.2016, the LU FSI is consolidated and renamed 'Institute of Philosophy and Sociology of the University of Latvia.",
  },
  about_us_text_8: {
    lv: "Pašos pirmsākumos Filozofijas nodaļa atradās LZA Ekonomikas institūta sastāvā, bet vēlāk tā tika iekļauta LZA Vēstures institūtā. Padomju laikā filozofijas pētniecība oficiāli noritēja zem marksisma karoga, taču filozofijas nodaļas ietvaros tika apzināta arī Latvijas sabiedriskās domas un filozofijas vēsture. Vēstures pētījumus vadīja LPSR ZA akadēmiķis Valentīns Šteinbergs, ar Latvijas filozofijas vēsturi nodarbojās Zaiga Lapiņa, Ella Buceniece, Pēteris Laizāns, Irēna Jakubaņeca un citi. Neraugoties uz oficiālajiem marksisma ietvariem, tika izstrādātas daudzas nozīmīgas filozofijas vēstures, ideju vēstures, fenomenoloģijas, hermeneitikas, ētikas un estētikas tēmas.",
    en: "At the very beginning, the Philosophy Department was part of the Institute of Economics of the LAS, but, later it got incorporated into the Institute of History of the Latvian Academy of Sciences. During the Soviet period, the philosophical research was officially conducted under the banner of Marxism, while the Philosophy Department was also studying philosophy and the history of Latvian public opinion. Historical research was led by Valentīns Šteinbergs, academician of the LSSR Academy of Sciences, and the history of Latvian philosophy by Zaiga Lapiņa, Ella Buceniece, Pēteris Laizāns, Irēna Jakubaņeca and others. Despite the official Marxist framework, many important topics in the history of philosophy, history of ideas, phenomenology, hermeneutics, ethics and aesthetics were developed.",
  },
  about_us_text_9: {
    lv: "Ar 1981. gadu Filozofijas nodaļā iestājās labāki laiki. Apvienojot juristus, sociologus un filozofus, tika nodibināts patstāvīgs zinātniski pētniecisks institūts, kurš saskaņā ar tā laika tradīcijām ieguva Filozofijas un tiesību institūta nosaukumu. (Šādi institūti LZA sistēmā radās visās republikās, izņemot Igauniju, kurā to nodibināja tikai astoņdesmito gadu vidū.) Jaunajam institūtam kļuva nepieciešamas plašākas telpas un tāpēc no LZA Augstceltnes nācās pārcelties uz Kaķu namu Vecrīgā, kur Filozofijas un tiesību institūtam tika piešķirta daļa no trešā un ceturtā stāva. Vecrīgas klātbūtne filozofijas garam iedvesa pavisam citu noskaņu, ļaujot izjust smalkās vēsturiskās un romantiskās strāvas, kā arī Rietumeiropas tālo elpu, kas kontrastēja ar padomju laika stagnāciju, ko savos pētījumos saskatīja, taču bija spiesti noklusēt tā laika sociologi.",
    en: "By 1981, better times dawned in the Philosophy Department. An independent scientific research institute was established by bringing together lawyers, sociologists and philosophers; in accordance with the traditions of the time, it was named the Institute of Philosophy and Law. (Such institutes got established all over the LAS network, across all republics, except Estonia where it was founded only in the mid-1980s.) The new Institute needed more space and, for that reason, it moved from the LAS multi-storey building into the part of the third and fourth-floor premises of the Institute of Philosophy and Law situated at the Cat House in the Old Town. The presence of the old town charm instilled a completely different mood to the spirit of philosophy, allowing to experience the subtle historical and romantic currents, as well as the distant breath of Western Europe, which contrasted with the stagnation of the Soviet period, which the sociologists of that time saw in their research but were forced to conceal.",
  },
  about_us_text_10: {
    lv: "Labā dzīve Kaķu namā beidzās 1995. gadā, kad Latvijas Zinātņu akadēmija atteicās no ēkas Rīgā, Meistaru ielā 10 un šī ēka nonāca Finansu ministrijas pārziņā. Finansisti, kā zināms, mīl naudu, tāpēc Institūtam par īri nācās maksāt arvien vairāk un vairāk... Vienīgā izeja bija atgriezties LZA Augstceltnē, kurā ar laipnu LZA prezidenta gādību institūtam tika iedalīts 5. stāvs.",
    en: "The good life of the Cat House came to an end in 1995, when the Latvian Academy of Sciences relinquished the building at 10 Meistaru Street in Riga to the Ministry of Finance. Financiers, as we know, love money, so the Institute had to pay more and more for rent... The only way out was to return to the LAS High Building, where, with the kind care of the LAS President, the Institute was allocated the 5th floor.",
  },
  about_us_text_11: {
    lv: "Kopš 2014.gada LU FSI mīt jaunās telpās, Humanitāro un sociālo zinātņu pētījumu centrā Kalpaka bulvārī 4, Kerkoviusa namā, kurā dzīvojam kopā ar LU LVI un LU Bibliotēku.",
    en: "Since 2014, the LU FSI is in new premises, at the Humanities and Social Sciences Research Centre at 4 Kalpaka Boulevard, Kerkovius's House, where we reside together with the LU LVI and the LU Library.",
  },
  about_us_history_title: {
    lv: "Vēsture",
    en: "History",
  },
  personnel_administration: {
    lv: "Administrācija",
    en: "Administration",
  },
  personnel_council: {
    lv: "Zinātniskā padome",
    en: "Scientific Council",
  },
  personnel_council_desc: {
    lv: "LU HZF FSI zinātniskā padome nodibināta 2025. gada 11. martā. Padomē ir 11 locekļi, ievēlēti uz 3 gadiem.",
    en: "The LU HZF FSI Scientific Council was established on March 11, 2025. It has 11 members who are elected for a three-year term.",
  },
  contact_write_message: {
    lv: "Rakstīt ziņu",
    en: "Contact Us",
  },
  contact_building_location: {
    lv: "Latvijas Universitātes bibliotēkas jaunajā stikla ēkā",
    en: "In the new glass building of the Library of the University of Latvia",
  },
  contact_send_button: {
    lv: "Nosūtīt",
    en: "Send",
  },
  contact_message_success: {
    lv: "Jūsu ziņa tika veiksmīgi nosūtīta",
    en: "Your message has been successfully sent",
  },
  contact_name_surname: {
    lv: "Vārds, uzvārds",
    en: "Name, surname",
  },
  contact_email: {
    lv: "E-pasts",
    en: "E-mail",
  },
  contact_text: {
    lv: "Ziņas teksts",
    en: "Your message",
  },
  publication_category_publications: {
    lv: "Publikācijas",
    en: "Publications",
  },
  publication_category_magazines: {
    lv: "Žurnāli",
    en: "Journals",
  },
  publication_newest_publications: {
    lv: "Jaunākās publikācijas",
    en: "Newest publications",
  },
  publication_newest_magazines: {
    lv: "Jaunākie žurnāli",
    en: "Latest Journals",
  },
  publication_compilers: {
    lv: "Sastādītājs:",
    en: "Compiler:",
  },
  publication_author: {
    lv: "Autors:",
    en: "Author:",
  },
  publication_translator: {
    lv: "Tulkotājs:",
    en: "Translator:",
  },
  publication_issue_at: {
    lv: "Izdots:",
    en: "Issued:",
  },
  publication_isbn: {
    lv: "ISBN:",
    en: "ISBN:",
  },
  publication_notes: {
    lv: "Piezīmes:",
    en: "Notes:",
  },
  publication_page_count: {
    lv: "lpp.",
    en: "pages",
  },
  publication_download_pdf: {
    lv: "Apskatīt PDF",
    en: "View PDF",
  },
  publication_back_to_desc: {
    lv: "Apakaļ uz aprakstu",
    en: "Back to description",
  },
  pagination_previous: {
    lv: "iepriekšējā",
    en: "previous",
  },
  pagination_next: {
    lv: "nākamā",
    en: "next",
  },
  no_entries_this_year: {
    lv: "Šajā gadā nav neviena ieraksta",
    en: "No entries have been found",
  },
  cookies_text: {
    lv: "Šī vietne izmanto sīkdatnes (cookies). Turpinot lietot šo lapu, Jūs automātiski piekrītat sīkdatņu izmantošanai. Sīkdatnes Jūs varat atslēgt pārlūkprogrammas iestatījumos.",
    en: "This website uses cookies. By continuing to use this site, you automatically agree to the use of cookies. You can disable cookies in your browser settings.",
  },
  cookies_accept: {
    lv: "Piekrītu",
    en: "Accept",
  },
  admin_redirect: {
    lv: "Tiek pārvirzīts uz CMS...",
    en: "Redirecting to CMS...",
  },
  search_search: {
    lv: "Meklēt",
    en: "Search",
  },
  search_title: {
    lv: "Ierakstiet meklēšanas frāzi",
    en: "Type search phrase",
  },
  search_note: {
    lv: "Informācija tiek meklēta starp jaunumu, rakstu, publikāciju un notikumu nosaukumiem un virsrakstiem",
    en: "Information will be searched between: news, articles, publications and events",
  },
  search_search_by: {
    lv: "Meklēt pēc...",
    en: "Search by",
  },
  search_nothing_found: {
    lv: "Pēc dotās frāzes nekas netika atrasts",
    en: "Nothing was found",
  },
  search_news: {
    lv: "Jaunums",
    en: "News",
  },
  search_article: {
    lv: "Raksts",
    en: "Article",
  },
  search_publication: {
    lv: "Publikācija",
    en: "Publication",
  },
  search_event: {
    lv: "Notikums",
    en: "Event",
  },
  developed_by: {
    lv: 'Izstrādāja',
    en: 'Developed by'
  }
};

export default locales;
