import { ConfigContext } from "context";
import { useContext } from "react";
import { getOtherLanguage } from "./utils";
import DarkBlueContainer from "components/container/DarkBlueContainer";
import NavigationButton from "./NavigationButton";
import MobileNavigationItem from "./MobileNavigationItem";
import { fallDown as Menu } from "react-burger-menu";
import routes from "pages/routes";
import { BlueContainer } from "components/container";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "assets/logo/new.svg";
import { CgClose as CloseIcon } from "react-icons/cg";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";
import FontSizeDropdown from "./FontSizeDropdown";

const MobileNavigation = ({ isOpen, onMenuIsOpen }) => {
  const { language, sections, configDispatch } = useContext(ConfigContext);
  const routerHistory = useHistory();

  const changeLanguage = () => {
    onMenuIsOpen(false);
    routerHistory.push(routes.home);
    configDispatch({ type: "change_language" });
  };

  const handleSearchOpen = () => {
    onMenuIsOpen(false);
    routerHistory.push(routes.search);
  };

  return (
    <Menu
      width={"100%"}
      customBurgerIcon={false}
      isOpen={isOpen}
      onStateChange={(state) => onMenuIsOpen(state.isOpen)}
      noTransition
      noOverlay
    >
      <div className="fixed-top shadow-sm">
        <BlueContainer>
          <Row className="py-3">
            <Col>
              <Link to={routes.home} onClick={() => onMenuIsOpen(false)}>
                <Logo />
              </Link>
            </Col>
            <Col>
              <Button
                className="bg-white float-right p-1 border-0"
                onClick={() => onMenuIsOpen(false)}
              >
                <CloseIcon className="tc-blue-4" style={{ fontSize: "50px" }} />
              </Button>
            </Col>
          </Row>
        </BlueContainer>
      </div>

      <BlueContainer>
        <Row style={{ paddingTop: "100px" }}>
          <Col>
            <ul className="no-style-list tc-white tfs-very-large ml-4 pb-5 mb-5">
              {sections.map((section) => (
                <MobileNavigationItem
                  key={section.id}
                  section={section}
                  onMenuIsOpen={() => onMenuIsOpen(false)}
                />
              ))}
            </ul>
          </Col>
        </Row>
      </BlueContainer>

      <div className="fixed-bottom shadow-sm">
        <DarkBlueContainer>
          <Row className="py-2 text-center">
            <Col>
              <NavigationButton
                className="tc-gray-3 tfs-large"
                onClick={handleSearchOpen}
              >
                <SearchIcon />
              </NavigationButton>
            </Col>
            <Col>
              <FontSizeDropdown sizeClass="tfs-large" />
            </Col>
            <Col>
              <NavigationButton
                className="tc-gray-3 tfs-large"
                onClick={changeLanguage}
              >
                {getOtherLanguage(language)}
              </NavigationButton>
            </Col>
          </Row>
        </DarkBlueContainer>
      </div>
    </Menu>
  );
};

export default MobileNavigation;
