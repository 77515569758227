const CONFIG = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  LANGAUGES: [
    {
      key: "lv",
      value: "LV",
    },
    {
      key: "en",
      value: "EN",
    },
  ],
  DEFAULT_LANGAUGE: "lv",
  FONT_SIZES: [75, 100, 125, 150, 175],
  DEFAULT_FONT_SIZE: 100,
  CONTACT: {
    email: "fsi@lu.lv",
    phone: "+371 67034861",
    address: "Kalpaka bulv. 4, Rīga",
  },
  SOCIAL_PROFILES: {
    facebook:
      "https://www.facebook.com/LuAgenturaFilozofijasUnSociologijasInstituts",
  },
  API_CACHE_EXPIRATION:
    parseInt(process.env.REACT_APP_API_CACHE_EXPIRATION) || 1000 * 60 * 5, // Milliseconds
  STORAGE_CACHE_EXPIRATION:
    parseInt(process.env.REACT_APP_STORAGE_CACHE_EXPIRATION) || 1000 * 60 * 5, // Milliseconds
  PUBLICATION_PER_PAGE: 12,
  ADMIN_URL: "https://fsilu.vip.lv",
  GA_TRACKING_ID: "UA-208545273-1",
  DEVELOPER: {
    name: "SIA Babri Software",
    url: "https://babrisoftware.com/",
  },
};

export default CONFIG;
