import { BlueContainer } from "components/container";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/logo/new.svg";
import { FaFacebook as FacebookIcon } from "react-icons/fa";
import FooterColumn from "./FooterColumn";
import { useContext } from "react";
import { ConfigContext } from "context";
import config from "config";
import { getGlobalFontSizeCSS } from "components/main/utils";
import { OTHER_SECTIONS, PUBLICATION_SECTIONS } from "./sections";
import T from "locales";

const Footer = () => {
  const { sections, font_size } = useContext(ConfigContext);

  return (
    <footer className={"mt-auto " + getGlobalFontSizeCSS(font_size)}>
      <BlueContainer className="pt-5">
        <Row>
          <Col className="tc-white" xs="12" md="4">
            <Row>
              <Col>
                <Logo width={200} />
              </Col>
            </Row>
            <Row className="tfs-small pt-3">
              <Col>
                <T k="phone_short" />{" "}
                <a href={"tel: " + config.CONTACT.phone}>
                  {config.CONTACT.phone}
                </a>
              </Col>
            </Row>
            <Row className="tfs-small pt-2">
              <Col>
                <T k="email" />{" "}
                <a href={"mailto: " + config.CONTACT.email}>
                  {config.CONTACT.email}
                </a>
              </Col>
            </Row>
            <Row className="tfs-small pt-2">
              <Col>
                <T k="address" /> {config.CONTACT.address}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <a
                  href={config.SOCIAL_PROFILES.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className="tfs-normal" />
                </a>
              </Col>
            </Row>
          </Col>

          {sections.map(
            (section) =>
              section.children.length > 0 && (
                <FooterColumn
                  title={section.name}
                  sections={section.children}
                  key={section.id}
                />
              )
          )}

          <FooterColumn
            title={<T k="publications" />}
            sections={PUBLICATION_SECTIONS}
          />
          <FooterColumn title={<T k="other" />} sections={OTHER_SECTIONS} />
        </Row>
        <Row>
          <Col className="tc-blue-2 tfs-very-small text-center pb-2 pt-5 font-weight-light">
            Copyright © LU FSI All rights reserved
          </Col>
        </Row>
        <Row>
          <Col className="tc-blue-2 tfs-very-small text-center pb-3 font-weight-light">
            <T k="developed_by" />
            &nbsp;
            <a href={config.DEVELOPER.url} target="_blank" rel="noreferrer">
              {config.DEVELOPER.name}
            </a>
          </Col>
        </Row>
      </BlueContainer>
    </footer>
  );
};

export default Footer;
